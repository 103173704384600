module.exports = {
  publicPath: '/console',
  devServer: {
    disableHostCheck: true,
    port: 8080,
    public: '127.0.0.1',
    sockPath: '/sockjs-node',
    proxy: {
      '/console/actuator': {
        target: 'http://localhost:8082',
        secure: false,
        changeOrigin: true
      },
      '/console/api': {
        target: 'http://localhost:8082',
        secure: false,
        changeOrigin: true
      }
    }
  },
  chainWebpack: config => {
    config.plugin('define').tap(args => {
      args[0]['process.env']['APP_VERSION'] = JSON.stringify(require('./package.json').version);
      return args
    });
    config.module.rule('vue').uses.delete('cache-loader');
    config.module.rule('js').uses.delete('cache-loader');
    config.module.rule('ts').uses.delete('cache-loader');
    config.module.rule('tsx').uses.delete('cache-loader');
  },
  outputDir: 'target/dist',
  assetsDir: 'console/public'
};
