<template>
  <div class="card p-5">
    <div class="card-header">
      <h3 class="card-header-title title is-4">Dev Hub Console</h3>
    </div>
    <div v-show="authenticated" class="card-content">
      <section class="content">
        <p>Welcome to the console for <strong>devhub-download.cisco.com</strong>.</p>
        <p>Dev Hub Download enables API-driven distribution of Cisco software.</p>
      </section>
      <section class="content">
        <h5 class="title is-5">Usage Instructions</h5>
        <ul>
          <li class="p-2">Generate a Download Key via the <strong><router-link to="/download-key">Manage Download Key</router-link></strong> page.</li>
          <li class="p-2">Use your <strong>Email Address</strong> and the <strong>Download Key</strong> from this page as credentials
            when authenticating with devhub-download.cisco.com APIs.</li>
          <li class="p-2">You must log into <a href="https://devhub-download.cisco.com/console">devhub-download.cisco.com/console</a> once
            every <strong>6 months</strong> to extend the duration of the Download Key.</li>
        </ul>
      </section>
    </div>
    <div class="card-content" v-show="!authenticated">
      <p>Please <router-link to="/">log in</router-link> to access Dev Hub Console</p>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'home',
    computed: {
      authenticated() {
        return this.$store.getters.isAuthenticated;
      }
    }
  }
</script>