import axios from 'axios';
import authHeader from './auth-header';

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

export default {

  oauthLogin(code) {
    return axios.get("/oidc/code?code=" + code).then(response => {
      return response;
    }).catch(error => {
      return error.response;
    });
  },
  get_download_key(email) {
    return axios.get(`/download/key`, { params: {"email": email}, headers: authHeader() }).then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
  },
  generate_download_key(email) {
    return axios.put(`/download/key`, {}, { params: {"email": email}, headers: authHeader() }).then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
  },
  revoke_download_key(email) {
    return axios.delete(`/download/key/${email}`, { headers: authHeader() }).then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
  },
}
