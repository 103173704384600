<template>
  <div id="app" class="page-wrapper">
    <Banner></Banner>
    <!-- Nav and content -->
    <div class="columns content-wrapper">
      <div class="column is-offset-one-quarter is-half">
        <!-- Page content -->
        <router-view></router-view>
      </div>
    </div>
    <CiscoFooter></CiscoFooter>
  </div>
</template>

<style lang="scss">
  @import "styles/devhub-style.scss";
</style>

<script>
import Banner from "./components/banner/BannerComponent";
import CiscoFooter from "./components/footer/CiscoFooterComponent"

export default {
    name: 'devhub-console',
    components: {CiscoFooter, Banner},
    watch: {
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Cisco Dev Hub Console';
        }
      },
    },
    mounted () {
      console.info('DevHub Console v' + this.$store.getters.getAppVersion)
    }
  }
</script>
