<template>
  <div class="card p-5">
    <section class="card-header">
      <h3 class="card-header-title title is-4">Manage Download Key</h3>
    </section>
    <div class="card-content">
      <div class="content">
        <p>Use the key below to authenticate to <strong>devhub-download.cisco.com</strong> repositories to retrieve software.</p>
      </div>
      <label for="downloadKeyInput" class="label">Download Key</label>
      <div class="field has-addons is-expanded">
        <div :class="{'is-loading':(loadingKey)}" class="control is-expanded has-icons-left">
          <input id="downloadKeyInput"
                 :type="keyFieldType"
                 class="input is-rounded password-field"
                 readonly="readonly"
                 v-model="downloadKey">
          <span class="icon is-small is-left">
            <font-awesome-icon icon="key" />
          </span>
        </div>
        <div class="control">
          <button class="button is-rounded" :disabled="loading" v-on:click="switchVisibility">
            <span class="icon is-small is-left">
              <font-awesome-icon icon="eye" />
            </span>
            <span>View</span>
          </button>
        </div>
        <div class="control">
          <button type="button" class="button is-rounded" :disabled="loading" v-on:click="doCopy">
            <span class="icon is-small is-left">
              <font-awesome-icon icon="copy" />
            </span>
            <span>Copy</span>
          </button>
        </div>
      </div>
      <div class="field">
        <span class="control">
          <button class="button is-rounded is-primary"
                  :disabled="loading"
                  :class="{'is-loading': (loadingGenerate)}"
                  v-on:click="generateDownloadKey">
            <span class="icon is-small is-left">
              <font-awesome-icon icon="cog" />
            </span>
            <span>Generate Key</span>
          </button>
        </span>
        <span class="control ml-5">
          <button class="button is-rounded is-primary"
                  :disabled="loading"
                  :class="{'is-loading': (loadingRevoke)}"
                  v-on:click="revokeDownloadKey"
                  v-if="hasDownloadKey">
            <span class="icon is-small is-left">
              <font-awesome-icon icon="trash" />
            </span>
            <span>Revoke Key</span>
          </button>
        </span>
      </div>
    </div>
    <section class="message" v-if="keyError">
      <div class="message-header">
        <p>Error</p>
        <button class="delete" aria-label="delete" v-on:click="clearDownloadError"></button>
      </div>
      <div class="message-body" v-html="keyError">
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'DownloadKey',
    data: function () {
      return {
        keyFieldType: 'password',
        keyError: null,
        loadingKey: false,
        loadingGenerate: false,
        loadingRevoke: false
      }
    },
    computed: {
      loading() {
        return this.loadingKey || this.loadingGenerate || this.loadingRevoke;
      },
      authenticated() {
        return this.$store.getters.isAuthenticated;
      },
      downloadKey() {
        return this.$store.getters.getDownloadKey;
      },
      hasDownloadKey() {
        return !(this.downloadKey == null || this.downloadKey === "");
      },
    },
    methods: {
      async getDownloadKey() {
        this.loadingKey = true;
        try {
          await this.$store.dispatch("getDownloadKey");
        } catch (e) {
          this.keyError = e;
        }
        this.loadingKey = false;
      },
      async generateDownloadKey() {
        this.loadingGenerate = true;
        this.loadingKey = true;
        try {
          await this.$store.dispatch("generateDownloadKey");
        } catch (e) {
          this.keyError = e;
        }
        this.loadingGenerate = false;
        this.loadingKey = false;
      },
      async revokeDownloadKey() {
        this.loadingRevoke = true;
        try {
          await this.$store.dispatch("revokeDownloadKey");
        } catch (e) {
          this.keyError = e;
        }
        this.loadingRevoke = false;
      },
      clearDownloadError() {
        return this.keyError = null;
      },
      doCopy: function () {
        this.$copyText(this.$store.getters.getDownloadKey).then(function () {
        }, function (e) {
          console.log(e)
        })
      },
      switchVisibility() {
        this.keyFieldType = this.keyFieldType === 'password' ? 'text' : 'password'
      }
    },
    async mounted() {
      if(!this.hasDownloadKey) {
        await this.getDownloadKey();
      }
    }
  }
</script>