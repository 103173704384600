<template>
  <div class="card">
    <!--  OAuth Initiate and finish  -->
    <div class="card" v-show="!authenticated && !oauthError">
      <section class="card-content">
        <div class="level content">
          <h3 class="level-item title is-3" v-text="oauthMessage" v-if="!authenticated && !oauthCode"></h3>
          <h3 class="level-item title is-3" v-if="authenticated || oauthCode">Finishing Login</h3>
        </div>
        <div class="level">
          <!-- Spinner animation -->
          <div class="level-item">
            <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      </section>
    </div>
    <!--  OAuth Failure  -->
    <div class="card" v-show="!authenticated && oauthError">
      <section class="card-header">
        <h3 class="card-header-title title is-5">Cisco Login Failed</h3>
      </section>
      <section class="card-content">
        <p class="is-justify-content-center py-3" v-html="oauthError"></p>
        <button class="button is-primary is-rounded" @click="oauthLogin()">Login Again</button>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'login',
    data: function() {
      return {
        oauthMessage: "Redirecting to Cisco Login",
        oauthConsoleBackendError: null
      }
    },
    computed: {
      authenticated() {
        return this.$store.getters.isAuthenticated;
      },
      urlParams() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        return params;
      },
      oauthCode() {
        return this.urlParams.get("code");
      },
      oauthState() {
        return this.urlParams.get("state");
      },
      oauthError() {
        return this.urlParams.get("error") || this.oauthConsoleBackendError;
      }
    },
    watch: {
      authenticated: function (val) {
        if(val) {
          this.$router.push("/home");
        }
      },
    },
    methods: {
      async oauthLogin() {
        return this.$store.dispatch('startOauthLogin');
      },
    },
    beforeMount() {
      this.isLoading = true;
      if(!this.authenticated) {
        // check if current page load is an OIDC callback, then forward to backend for final login
        if(this.oauthCode !== null) {
          return this.$store.dispatch("finishOauthLogin", this.oauthCode)
              .catch(err => {
                this.oauthConsoleBackendError = err;
                console.error(err);
              });
        }
        // if error is set, then do nothing and allow error to display
        if(this.oauthError && this.oauthError.trim()) {
          return;
        }
        // if there is no code, then initiate login
        return this.oauthLogin();
      } else {
        return this.$router.push({path: '/home'});
      }
    }
  }
</script>