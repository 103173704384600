import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/backend-api'

Vue.use(Vuex);

const GENERIC_ERROR_TEXT = "<p class='my-3'>Please try again later or " +
    "<a href='https://www.cisco.com/c/en/us/support/index.html'>open a support case</a> if the error persists.</p>"

export default new Vuex.Store({
  state: {
    appVersion: process.env.APP_VERSION || '0',
    authSuccess: sessionStorage.getItem('auth-token') !== null || false,
    authError: false,
    authErrorMsg: null,
    currentUser: JSON.parse(sessionStorage.getItem('user-details')) || null,
    downloadKey: "",
  },
  mutations: {
    auth_success(state, payload){
      state.authSuccess = true;
      state.currentUser = payload;
    },
    auth_error(state, payload){
      state.authError = true;
      state.authErrorMsg = payload;
    },
    log_out(state) {
      state.authSuccess = false;
      state.currentUser = null;
    },
    downloadKey_create(state, key) {
      state.downloadKey = key;
    },
    downloadKey_revoke(state) {
      state.downloadKey = "";
    },
  },
  actions: {
    startOauthLogin() {
      let oauthState = '202103'; // todo: either 1. random gen + validate or 2. remove state

      return window.location.href = process.env.VUE_APP_CISCO_OAUTH_URL
          + '?response_type=code'
          + '&client_id=' + process.env.VUE_APP_CISCO_OAUTH_CLIENT_ID
          + '&scope=' + process.env.VUE_APP_CISCO_OAUTH_SCOPE
          + '&redirect_uri=' + process.env.VUE_APP_CISCO_OAUTH_REDIRECT
          + '&state=' + oauthState;
    },
    finishOauthLogin({commit}, oauthCode) {
      return new Promise((resolve, reject) => {
        api.oauthLogin(oauthCode)
            .then(response => {
              if(response.status === 200) {
                let userDetails = response.data;
                let currentUser = {
                  "name": userDetails.name,
                  "username": userDetails.userId,
                  "company": userDetails.company,
                  "email": userDetails.email
                };
                sessionStorage.setItem('auth-token', userDetails.value);
                sessionStorage.setItem('user-details', JSON.stringify(currentUser));

                // Strip off oidc code
                window.history.replaceState(null, null, window.location.pathname);
                commit('auth_success', currentUser);
              } else if(response.status > 399) {
                console.error("[Error] Final OIDC failed: " + response.data);
                commit('auth_error', response.statusText + ": " + response.data);
                throw "Server error encountered while completing Cisco log in.";
              }
              resolve(response);
            })
            .catch(error => {
              // Strip off oidc code
              window.history.replaceState(null, null, window.location.pathname);
              commit('auth_error', error);
              reject("[Error] " + error + GENERIC_ERROR_TEXT);
            })
      });
    },
    logout({commit}) {
      return new Promise((resolve) => {
        sessionStorage.removeItem('auth-token');
        sessionStorage.removeItem('user-details');
        commit('log_out');
        resolve();
      })
    },
    generateDownloadKey({commit, state}) {
      return new Promise((resolve, reject) => {
        api.generate_download_key(state.currentUser.email)
            .then(response => {
              if(response.status === 200) {
                commit('downloadKey_create', JSON.parse(response.data).apiKey);
              } else if(response.status > 399) {
                console.error("[Error] generate download key: " + response.statusText + ": " + JSON.stringify(response.data));
                throw response.statusText + ": Unable to generate download key. " + GENERIC_ERROR_TEXT;
              }
              resolve(response);
            })
            .catch(error => {
              reject("[Error] " + error);
            })
      });
    },
    getDownloadKey({commit, state}) {
      return new Promise((resolve, reject) => {
        api.get_download_key(state.currentUser.email)
            .then(response => {
              if(response.status === 200) {
                let keyResponse = JSON.parse(response.data);
                if(keyResponse == null || keyResponse === {}) {
                  commit('downloadKey_create', "");
                } else {
                  commit('downloadKey_create', keyResponse.apiKey)
                }
              } else if(response.status > 399) {
                console.error("[Error] retrieve download key: " + response.statusText + ": " + JSON.stringify(response.data));
                throw response.statusText + ": Unable to retrieve download key. " + GENERIC_ERROR_TEXT;
              }
              resolve(response);
            })
            .catch(error => {
              reject("[Error] " + error);
            })
      });
    },
    revokeDownloadKey({commit, state}) {
      return new Promise((resolve, reject) => {
        api.revoke_download_key(state.currentUser.email)
            .then(response => {
              if(response.status === 200) {
                commit('downloadKey_revoke');
              } else if(response.status > 399) {
                console.error("[Error] revoke download key: " + response.statusText + ": " + JSON.stringify(response.data));
                throw response.statusText + ": Unable to revoke download key, " + GENERIC_ERROR_TEXT;
              }
              resolve(response);
            })
            .catch(error => {
              commit('downloadKey_error', error);
              reject("Unable to revoke key");
            })
      });
    },
  },
  getters: {
    isAuthenticated: state => state.authSuccess,
    getAuthError: state => state.authError,
    getAppVersion: state => state.appVersion,
    getCurrentUser: state => state.currentUser,
    getDownloadKey: state => state.downloadKey,
  }
})
