import Vue from 'vue'
import Router from 'vue-router'
import {publicPath} from '../../vue.config'
import Home from '../components/home/HomeComponent'
import Login from '../components/login/LoginComponent'
import DownloadKey from '../components/download-key/DownloadKeyComponent'
import store from '../store/store'

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  base: publicPath,
  routes: [
    {
      path: '/',
      name: "login",
      component: Login,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/home',
      name: "home",
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/download-key',
      name: "DownloadKey",
      component: DownloadKey,
      meta: {
        requiresAuth: true
      }
    },
    { path: '*', redirect: '/home'}
  ]
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(!store.getters.isAuthenticated && !to.params.logout) {
      next({
        path: '/'
      })
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
