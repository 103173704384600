<template>
  <div>
    <footer class="footer has-text-light has-text-link-light">
      <div class="level">
        <span class="level-item">
          <a class="has-text-link-light" href="https://www.cisco.com/c/en/us/about/contact-cisco.html">Contacts</a>
        </span>
        <span class="level-item">
          <a class="has-text-link-light" href="https://www.cisco.com/c/en/us/about/help.html">Help</a>
        </span>
        <span class="level-item">
          <a class="has-text-link-light" href="http://www.cisco.com/c/en/us/about/sitemap.html">Site Map</a>
        </span>
        <span class="level-item">
          <a class="has-text-link-light" href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html">Terms & Conditions</a>
        </span>
        <span class="level-item">
          <a class="has-text-link-light" href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html">Privacy Statement</a>
        </span>
        <span class="level-item">
          <a class="has-text-link-light" href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies">Cookie Policy</a>
        </span>
        <span class="level-item">
          <a class="has-text-link-light" href="https://www.cisco.com/c/en/us/about/legal/trademarks.html">Trademarks</a>
        </span>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    name: 'CiscoFooter',
    computed: {
      appVersion() {
        return this.$store.getters.getAppVersion;
      },
    },
  }
</script>