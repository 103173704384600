import Vue from 'vue'
import App from './App'
import VueClipboard from 'vue-clipboard2'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCog, faCopy, faEye, faKey, faTrash, faUser} from '@fortawesome/free-solid-svg-icons'
import router from './route/router'
import store from './store/store'
import './../node_modules/bulma/bulma.sass'
import './styles/devhub-style.scss';

library.add(faKey)
library.add(faEye)
library.add(faCopy)
library.add(faTrash)
library.add(faCog)
library.add(faUser)

Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  render: h => h(App),
  store,
  router,
  components: { App }
}).$mount('#app');
